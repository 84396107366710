<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}~{{ parseInt(endTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 1}" @click="handleChangeTime(1)">1{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 2}" @click="handleChangeTime(2)">7{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">30{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 4}" @click="handleDatePicker(4)">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								@change="changeDatePicker($event,4)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 5}" @click="handleDatePicker(5)">{{$t('sycm.actions.week')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="weekData"
								type="week"
								ref="weekPicker"
								format="WW"
								:placeholder="$t('sycm.placeholder.week')"
								:picker-options="pickerWeekOptions"
								@change="changeDatePicker($event,5)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 6}" @click="handleDatePicker(6)">{{$t('sycm.actions.month')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="monthData"
								type="month"
								ref="monthPicker"
								:placeholder="$t('sycm.placeholder.month')"
								:picker-options="pickerMonthOptions"
								@change="changeDatePicker($event,6)">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.trade.overview')}}</div>
				<div class="barbox">
					<el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
						<span class="el-dropdown-link">
							{{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="section-main">
				<div class="sycm-trade">
					<div class="trade-main">
						<div class="trade-row" v-if="visitorsData">
							<div class="item">
								<div class="name">
									<strong>{{$t('sycm.common.visitor')}}</strong>
									<i :class="visitorsData.values < visitorsData.comp_values ? 'el-icon-bottom' : 'el-icon-top'"></i>
									<span v-if="visitorsData.rate">{{visitorsData.rate | priceFilter()}}%</span>
									<span v-else>-</span>
								</div>
								<div class="number">{{visitorsData.values}}</div>
							</div>
							<div class="item"></div>
							<div class="item"></div>
						</div>
						<div class="trade-row">
							<div class="item" v-if="orderData.buyers">
								<div class="name">
									<strong>{{$t('sycm.flow.buyersPlaced')}}</strong>
									<i :class="orderData.buyers.values < orderData.buyers.comp_values ? 'el-icon-bottom' : 'el-icon-top'"></i>
									<span v-if="orderData.buyers.rate > 0">{{orderData.buyers.rate | priceFilter()}}%</span>
									<span v-else>-</span>
								</div>
								<div class="number">{{orderData.buyers.values}}</div>
							</div>
							<div class="item" v-if="orderData.price">
								<div class="name">
									<strong>{{$t('sycm.tableHead.orderMoney')}}</strong>
									<i :class="parseFloat(orderData.price.values) < parseFloat(orderData.price.comp_values) ? 'el-icon-bottom' : 'el-icon-top'"></i>
									<span v-if="orderData.price.rate > 0">{{orderData.price.rate | priceFilter()}}%</span>
									<span v-else>-</span>
								</div>
								<div class="number">{{orderData.price.values | priceFilter()}}</div>
							</div>
							<div class="item"></div>
						</div>
						<div class="trade-row">
							<div class="item" v-if="paymentsData.buyers">
								<div class="name">
									<strong>{{$t('sycm.common.payBuyers')}}</strong>
									<i :class="paymentsData.buyers.values < paymentsData.buyers.comp_values ? 'el-icon-bottom' : 'el-icon-top'"></i>
									<span v-if="paymentsData.buyers.rate > 0">{{paymentsData.buyers.rate | priceFilter()}}%</span>
									<span v-else>-</span>
								</div>
								<div class="number">{{paymentsData.buyers.values}}</div>
							</div>
							<div class="item" v-if="paymentsData.price">
								<div class="name">
									<strong>{{$t('sycm.common.payMoney')}}</strong>
									<i :class="parseFloat(paymentsData.price.values) < parseFloat(paymentsData.price.comp_values) ? 'el-icon-bottom' : 'el-icon-top'"></i>
									<span v-if="paymentsData.price.rate > 0">{{paymentsData.price.rate | priceFilter()}}%</span>
									<span v-else>-</span>
								</div>
								<div class="number">{{paymentsData.price.values | priceFilter()}}</div>
							</div>
							<div class="item" v-if="paymentsData.unit_price">
								<div class="name">
									<strong>{{$t('sycm.trade.perTicketSales')}}</strong>
									<i :class="parseFloat(paymentsData.unit_price.values) < parseFloat(paymentsData.unit_price.comp_values) ? 'el-icon-bottom' : 'el-icon-top'"></i>
									<span v-if="paymentsData.unit_price.rate > 0">{{paymentsData.unit_price.rate | priceFilter()}}%</span>
									<span v-else>-</span>
								</div>
								<div class="number">{{paymentsData.unit_price.values | priceFilter()}}</div>
							</div>
						</div>
					</div>
					<div class="trade-box">
						<div class="item">
							<div class="item-name">{{$t('sycm.trade.visit')}}</div>
							<div class="item-box">
								<div class="name">{{$t('sycm.tableHead.orderConversion')}}</div>
								<div class="number">{{conversionRate.order | priceFilter()}}%</div>
							</div>
						</div>
						<div class="item">
							<div class="item-name">{{$t('sycm.trade.placeOrder')}}</div>
							<div class="item-box">
								<div class="name">
									<span>{{$t('sycm.trade.orderPayConversion')}}</span>
									<el-popover placement="bottom" width="240" trigger="hover">
										<i slot="reference" class="el-icon-question"></i>
										<div class="popover-text">{{$t('sycm.popover.orderPayConversion1')}}</div>
									</el-popover>
								</div>
								<div class="number" v-if="conversionRate.order_pay>0">{{conversionRate.order_pay | priceFilter()}}%</div>
								<div class="number" v-else>-</div>
							</div>
						</div>
						<div class="item">
							<div class="item-name">{{$t('sycm.trade.payment')}}</div>
							<div class="item-box">
								<div class="name">{{$t('sycm.common.payConversion')}}</div>
								<div class="number">{{conversionRate.pay | priceFilter()}}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchTradeOverview } from "@/api/sycm"
export default {
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		}
	},
	data() {
		return {
			terminalType: '',
			dateType: 1,
			dayData: '',
			weekData: '',
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			pickerWeekOptions: {
				disabledDate(time) {
					const today = new Date()
					const day = today.getDay()
					today.setDate(today.getDate()-day)
					const end_time = today.getTime()
					today.setDate(today.getDate()-84)
					const start_time = today.getTime()
					return time.getTime() >end_time || time.getTime() < start_time
				},
				firstDayOfWeek: 1
			},
			pickerMonthOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-1)
					const end_time = today.getTime()
					today.setMonth(today.getMonth()-11)
					const start_time = today.getTime()
					return time.getTime() > end_time || time.getTime() < start_time
				}
			},
			startTime: '',
			endTime: '',
			visitorsData: {},
			orderData: {},
			paymentsData: {},
			conversionRate: {}
		}
	},
	created () {
		this.handleChangeTime()
	},
	methods: {
		async getData(){
			const loading = this.$loading()
			try {
				const params = {
					source: this.terminalType,
					time_from: parseInt(this.startTime / 1000),
					time_to: parseInt(this.endTime / 1000),
					date_type: this.dateType
				}
				const { data } = await fetchTradeOverview(params)
				this.visitorsData = data.visitors
				this.orderData = data.orders
				this.paymentsData = data.pay_orders
				this.conversionRate = data.conversion_rate
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		handleChangeTime(val) {
			this.dateType = val ? val : 1
			let today = new Date()
			switch(this.dateType) {
				case 1:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 2:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-7).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 3:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleDatePicker(type) {
			switch(type) {
				case 4:
					this.$refs.dayPicker.focus()
					break;
				case 5:
					this.$refs.weekPicker.focus()
					break;
				case 6:
					this.$refs.monthPicker.focus()
					break;
			}
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			switch(type) {
				case 4:
					this.monthData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
					break;
				case 5:
					this.dayData = ''
					this.monthData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+6).getTime() - 1000
					break;
				case 6:
					this.dayData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth()+1,1).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleCommand(command) {
			this.terminalType = command
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;
			}

			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;

				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}

			.barbox {
				.item-link {
					color: $--sycm-primary;
					cursor: pointer;
					display: inline-block;

					span {
						margin-right: 5px;
					}

					&:hover {
						color: $--sycm-hover-1;
					}
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-trade {
		position: relative;
		padding: 40px 10px 10px;
		.trade-main {
			width: 676px;
			.trade-row {
				padding: 0 15px;
				height: 68px;
				display: flex;
				font-size: 12px;
				line-height: 1.5;
				box-sizing: border-box;
				border-bottom: 1px solid #e5e5e5;
				.item {
					flex: 1;
					display: flex;
					justify-content: center;
					flex-flow: column;
					padding: 0 20px;
					[class^=el-icon-] {
						margin: 0 4px;
						font-weight: bold;
						&.el-icon-top {
							color: #fe7c24;
						}
						&.el-icon-bottom {
							color: #00ba26;
						}
					}
				}
				&:nth-child(1) {
					color: #5faead;
					background-color: #eefbfa;
				}
				&:nth-child(2) {
					color: #7f9c67;
					background-color: #f7fff1;
				}
				&:nth-child(3) {
					color: #bc853f;
					background-color: #fffbf4;
					border-bottom: none;
				}
			}
		}
		.trade-box {
			top: 40px;
			right: 10px;
			width: 433px;
			height: 204px;
			padding-right: 156px;
			box-sizing: border-box;
			position: absolute;
			background-image: url(../../../assets/images/sycm/trade-bg.png);
			background-repeat: no-repeat;
			background-size: auto 100%;
			.item {
				position: relative;
				.item-name {
					color: #ffffff;
					height: 68px;
					line-height: 68px;
					text-align: center;
					font-size: 18px;
					font-weight: 400;
				}
				.item-box {
					font-size: 12px;
					line-height: 1.5;
					position: absolute;
					.name {
						font-weight: bold;
					}
					.number {
						color: #333333;
					}
					.el-popover__reference {
						margin-left: 4px;
					}
				}
				&:nth-child(1) {
					.item-box {
						right: -50px;
						top: 50px;
						color: #7f9c67;
					}
				}
				&:nth-child(2) {
					.item-box {
						right: -50px;
						top: 60px;
						color: #bc853f;
					}
				}
				&:nth-child(3) {
					.item-box {
						right: -50%;
						bottom: 4px;
						color: #5faead;
					}
				}
			}
		}
	}
}
</style>